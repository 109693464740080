import i18n, { i18n as i18nApi } from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en/ns.json';
import ru from './ru/ns.json';

export function initI18n(locale?: string): i18nApi {
    i18n
        .use(initReactI18next)
        .init({
            resources: {
                en: { translation: en },
                ru: { translation: ru },
            },
            lng: "ru",
            fallbackLng: "ru",
        });
    return i18n;
}
