import React, {useEffect, useState} from "react";
import {styled} from "@mui/system";
import {
    Box,
    Container,
    Grid,
    Link,
    Skeleton,
    Typography
} from "@mui/material";
import routes from "../routes";
import ArrowUpRight from "./icons/ArrowUpRight";
import theme from "../theme";
import BoxFlex from "./layout/BoxFlex";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import {getSocialLinkNameByKey, isDev, sleep, tgBot} from "../utils";
import api from "../api/api";
import BoxFlexColumn from "./layout/BoxFlexColumn";
import PersonalDataProcessingDialog from "./dialogs/PersonalDataProcessingDialog";
import OfferDialog from "./dialogs/OfferDialog";
import {useTranslation} from "react-i18next";

const FooterBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.surface.dark,
    padding: "180px 0 130px",
    marginTop: "-80px",

    "@media (max-width: 899px)": {
        padding: "110px 0 60px",
    },
})) as typeof Box;

const CopyrightTitle = styled(Typography)({
    "@media (max-width: 899px)": {
        textAlign: "center",
        marginBottom: "40px",
    },
}) as typeof Typography;

const LinksContainer = styled(Box)({
    display: "grid",
    gridGap: "20px 184px",
    gridTemplateColumns: "1fr 1fr",

    "@media (max-width: 899px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gridGap: "16px",
    },
}) as typeof Box;

const SocialLinksContainer = styled(BoxFlex)({
    alignItems: "center",
    gridGap: "21px",

    "@media (max-width: 899px)": {
        justifyContent: "center",
        marginBottom: "40px",
    },
}) as typeof BoxFlex;

const useStyles = makeStyles({
    link: {
        display: "flex",
    },
    hideMobile: {
        "@media (max-width: 899px)": {
            display: "none",
        },
    },
});

const Footer = () => {
    const { t } = useTranslation();
    const navItems = [
        {
            title: t("Footer.platform"),
            url: routes.platform,
            isExternal: false,
            mobileHide: true,
        },
        {
            title: t("Footer.about"),
            url: routes.about,
            isExternal: false,
            mobileHide: true,
        },
        {
            title: t("Footer.diary"),
            url: tgBot,
            isExternal: true,
            mobileHide: true,
        },
        {
            title: t("Footer.contacts"),
            url: routes.contacts,
            isExternal: false,
            mobileHide: true,
        },
        {
            title: t("Footer.makeAnAppointment"),
            url: routes.specialists,
            isExternal: false,
            mobileHide: true,
        },
    ];
    const classes = useStyles();
    const [socialLinks, setSocialLinks] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const fetchSocialLinks = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../mocks/socialLinks__getData.json")

            setSocialLinks(response)
        } else {
            await api.get(`/social-links`)
                .then(res => {
                    if (res.status === 200) {
                        setSocialLinks(res.data)
                    }
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchSocialLinks()
    }, [])

    return (
        <FooterBox
            component="footer"
        >
            <Container maxWidth="xl">
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <BoxFlex
                            sx={{
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <BoxFlexColumn>
                                <CopyrightTitle
                                    variant="subtitle1"
                                    color="white.main"
                                >
                                    ООО "Без Боли" 2024
                                </CopyrightTitle>
                                <BoxFlexColumn
                                    sx={{
                                        margin: "19px 0 30px",

                                        "@media (max-width: 899px)": {
                                            alignItems: "center",
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="white.dark"
                                    >
                                        painpoint.pp@gmail.com
                                    </Typography>
                                </BoxFlexColumn>
                            </BoxFlexColumn>
                        </BoxFlex>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <LinksContainer>
                            {navItems.map((val, i) => (
                                <React.Fragment
                                    key={i}
                                >
                                    {navItems[i].isExternal ? (
                                        <a
                                            href={navItems[i].url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                                classNames({
                                                    [classes.link]: true,
                                                    [classes.hideMobile]: navItems[i].mobileHide,
                                                })
                                            }
                                        >
                                            <BoxFlex
                                                sx={{
                                                    alignItems: "center",
                                                    gridGap: "8px",
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="white.dark"
                                                >
                                                    {navItems[i].title}
                                                </Typography>
                                                <ArrowUpRight
                                                    width={17}
                                                    height={17}
                                                    color={theme.palette.white.dark}
                                                />
                                            </BoxFlex>
                                        </a>
                                    ) : (
                                        <Link
                                            href={navItems[i].url}
                                            sx={{
                                                display: {
                                                    xs: navItems[i].mobileHide ? "none" : "block",
                                                    md: "block",
                                                },
                                                order: i === navItems.length - 1 ? 1 : -1
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="white.dark"
                                            >
                                                {navItems[i].title}
                                            </Typography>
                                        </Link>
                                    )}
                                </React.Fragment>
                            ))}
                            <Box
                                sx={{
                                    order: 0,
                                }}
                            >
                                <PersonalDataProcessingDialog
                                    title={
                                        <Typography
                                            variant="body2"
                                            color="white.dark"
                                        >
                                            {t("Footer.privacyPolicy")}
                                        </Typography>
                                    }
                                />
                            </Box>

                            <Box
                                sx={{
                                    order: 2,
                                }}
                            >
                                <OfferDialog
                                    title={
                                        <Typography
                                            variant="body2"
                                            color="white.dark"
                                        >
                                            {t("Footer.publicOffer")}
                                        </Typography>
                                    }
                                />
                            </Box>
                        </LinksContainer>
                    </Grid>
                </Grid>
            </Container>
        </FooterBox>
    );
}

export default Footer;