import React, {ReactNode, useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, Link, Typography} from "@mui/material";
import theme from "../../theme";
import Xmark from "../icons/Xmark";
import {useTranslation} from "react-i18next";

export default function PersonalDataProcessingDialog(props: {
    title?: ReactNode
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false)

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link
                href="#"
                onClick={() => setOpen(true)}
            >
                {props.title ?? t("PersonalDataProcessingDialog.title")}
            </Link>
            <Dialog
                onClose={handleClose}
                open={open}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 6,
                            top: 6,
                        }}
                    >
                        <Xmark
                            color={theme.palette.text.tertiary}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4">
                        {t("PersonalDataProcessingDialog.startTitle")}
                    </Typography>
                    <br />
                    <Typography variant="body2">
                        Настоящая Политика конфиденциальности персональных данных (далее — Политика конфиденциальности) действует в отношении всей информации, которую ООО "Без Боли", расположенный на доменном имени https://ppain.ru/, может получить о Пользователе во время использования сайта https://ppain.ru/ .
                    </Typography>
                    <br />
                    <Typography variant="h4">
                        1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ
                    </Typography>
                    <br />
                    <Typography variant="body2">
                        1.1. В настоящей Политике конфиденциальности используются следующие термины:
                    </Typography>
                    <Typography variant="body2">
                        1.1.1. «Администрация сайта https://ppain.ru/ (далее — Администрация сайта) — уполномоченные сотрудники на управления сайтом, действующие от имени ООО "Без Боли", которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                    </Typography>
                    <Typography variant="body2">
                        1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
                    </Typography>
                    <Typography variant="body2">
                        1.1.3. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                    </Typography>
                    <Typography variant="body2">
                        1.1.4. «Конфиденциальность персональных данных» — обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
                    </Typography>
                    <Typography variant="body2">
                        1.1.5. «Пользователь сайта https://ppain.ru/ (далее Пользователь)» — лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее сайт https://ppain.ru/.
                    </Typography>
                    <Typography variant="body2">
                        1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
                    </Typography>
                    <Typography variant="body2">
                        1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
                    </Typography>
                    <br/>
                    <Typography variant="h4">
                        2. ОБЩИЕ ПОЛОЖЕНИЯ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    2.1. Использование Пользователем сайта https://ppain.ru/ означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
                    </Typography>
                    <Typography variant="body2">
                    2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта https://ppain.ru/.
                    </Typography>
                    <Typography variant="body2">
                    2.3. Настоящая Политика конфиденциальности применяется только к сайту https://ppain.ru/. ООО "Без Боли" не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте.
                    </Typography>
                    <Typography variant="body2">
                    2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта https://ppain.ru/.
                    </Typography>
                    <br />
                    <Typography variant="h4">
                    3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта https://ppain.ru/ по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте https://ppain.ru/ или при оформлении заказа на услугу.
                    </Typography>
                    <Typography variant="body2">
                    3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте https://ppain.ru/ ООО "Без Боли":
                    </Typography>
                    <Typography variant="body2">
                    3.2.1. фамилию, имя, отчество Пользователя;
                    </Typography>
                    <Typography variant="body2">
                    3.2.2. контактный телефон Пользователя;
                    </Typography>
                    <Typography variant="body2">
                    3.2.3. адрес электронной почты (e-mail);
                    </Typography>
                    <Typography variant="body2">
                    3.3. https://ppain.ru/ защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы («пиксель»):
                        • IP адрес;
                        • информация из cookies;
                        • информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);
                        • время доступа;
                        • адрес страницы, на которой расположен рекламный блок;
                        • реферер (адрес предыдущей страницы).
                    </Typography>
                    <Typography variant="body2">
                    3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.
                    </Typography>
                    <Typography variant="body2">
                    3.4. Любая иная персональная информация неоговоренная выше (используемые браузеры и операционные системы и т. д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    4.1. Персональные данные Пользователя Администрация сайта https://ppain.ru/ может использовать в целях:
                    </Typography>
                    <Typography variant="body2">
                    4.1.1. Идентификации Пользователя, зарегистрированного на сайте https://ppain.ru/, для оформления услуги.
                    </Typography>
                    <Typography variant="body2">
                    4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта https://ppain.ru/.
                    </Typography>
                    <Typography variant="body2">
                    4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта https://ppain.ru/, оказания услуг, обработка запросов и заявок от Пользователя.
                    </Typography>
                    <Typography variant="body2">
                    4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
                    </Typography>
                    <Typography variant="body2">
                    4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                    </Typography>
                    <Typography variant="body2">
                    4.1.6. Создания учетной записи для совершения покупок услуг, если Пользователь дал согласие на создание учетной записи.
                    </Typography>
                    <Typography variant="body2">
                    4.1.7. Уведомления Пользователя Сайта https://ppain.ru/ о состоянии Заказа.
                    </Typography>
                    <Typography variant="body2">
                    4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа, определения права на получение кредитной линии Пользователем.
                    </Typography>
                    <Typography variant="body2">
                    4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта https://ppain.ru/.
                    </Typography>
                    <Typography variant="body2">
                    4.1.10. Предоставления Пользователю с его согласия, обновлений услуг, специальных предложений, информации о ценах и иных сведений от имени сайта https://ppain.ru/ и ООО "Без Боли".
                    </Typography>
                    <Typography variant="body2">
                    4.1.11. Осуществления рекламной деятельности с согласия Пользователя.
                    </Typography>
                    <Typography variant="body2">
                    4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров сайта обновлений и услуг.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
                    </Typography>
                    <Typography variant="body2">
                    5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте https://ppain.ru/ ООО "Без Боли".
                    </Typography>
                    <Typography variant="body2">
                    5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                    </Typography>
                    <Typography variant="body2">
                    5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.
                    </Typography>
                    <Typography variant="body2">
                    5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                    </Typography>
                    <Typography variant="body2">
                    5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    6. ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ ВИДЕОХОСТИНГА
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    6.1. Передаваемые права третьим лицам:
                    Размещая пользовательский контент посредством использования наших услуг, вы предоставляете каждому пользователю Сервиса неисключительную, безвозмездную, действующую во всем мире лицензию на доступ к вашему Контенту и его использование в пределах, допускаемых функционалом Сервиса, в том числе на отображение его с помощью плеера Сервиса на сайтах третьих лиц посредством технологии embed (iframe), а также разрешаете создание временных технических копий контента и видео-превью такого контента.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    7. ОБЯЗАТЕЛЬСТВА СТОРОН
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    7.1. Пользователь обязан:
                    </Typography>
                    <Typography variant="body2">
                    7.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом https://ppain.ru/.
                    </Typography>
                    <Typography variant="body2">
                    7.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.
                    </Typography>
                    <Typography variant="body2">
                    7.2. Администрация сайта обязана:
                    </Typography>
                    <Typography variant="body2">
                    7.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.
                    </Typography>
                    <Typography variant="body2">
                    7.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
                    </Typography>
                    <Typography variant="body2">
                    7.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
                    </Typography>
                    <Typography variant="body2">
                    7.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    8. ОТВЕТСТВЕННОСТЬ СТОРОН
                    </Typography>
                    <Typography variant="body2">
                    8.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
                    </Typography>
                    <Typography variant="body2">
                    8.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:
                    </Typography>
                    <Typography variant="body2">
                    8.2.1. Стала публичным достоянием до её утраты или разглашения.
                    </Typography>
                    <Typography variant="body2">
                    8.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
                    </Typography>
                    <Typography variant="body2">
                    8.2.3. Была разглашена с согласия Пользователя.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    9. РАЗРЕШЕНИЕ СПОРОВ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    9.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта https://ppain.ru/ и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
                    </Typography>
                    <Typography variant="body2">
                    9.2.Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
                    </Typography>
                    <Typography variant="body2">
                    9.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.
                    </Typography>
                    <Typography variant="body2">
                    9.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.
                    </Typography>
                    <br/>

                    <Typography variant="h4">
                    10. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
                    </Typography>
                    <br/>
                    <Typography variant="body2">
                    10.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
                    </Typography>
                    <Typography variant="body2">
                    10.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте https://ppain.ru/, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                    </Typography>
                    <Typography variant="body2">
                    10.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать в по электронной почте painpoint.pp@gmail.com.
                    </Typography>
                    <Typography variant="body2">
                    10.4. Действующая Политика конфиденциальности размещена на странице по адресу https://ppain.ru/
                    </Typography>

                </DialogContent>
            </Dialog>
        </>
    );
}