import React, {useEffect, useRef, useState} from "react";
import {Box, Container, Grid, Skeleton, Typography, useScrollTrigger} from "@mui/material";
import {styled} from "@mui/system";
import mindImg from "../../img/control-pain-mind.png";
import bodyImg from "../../img/control-pain-body.png";
import LinkList from "../data-display/LinkList";
import BoxFlex from "../layout/BoxFlex";
import theme from "../../theme";
import ArrowSmallRight from "../icons/ArrowSmallRight";
import routes from "../../routes";
import Carousel from "react-multi-carousel";
import {isDev, sleep} from "../../utils";
import api from "../../api/api";
import {useTranslation} from "react-i18next";

const PainImgWrapper = styled(BoxFlex)(({theme}) => ({
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    backgroundColor: theme.palette.surface.main,
    margin: "0 auto",

    "@media (max-width: 899px)": {
        maxWidth: "320px",
    },

    "& img": {
        maxWidth: "100%",
    },
})) as typeof BoxFlex;

export default function ControlPainBlock() {
    const { t } = useTranslation();
    const ref = useRef(null)
    const trigger = useScrollTrigger();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<{ title: string, categories: { id: number, title: string }[] }[]>([])

    const fetchData = async () => {
        if (isDev) {
            await sleep(500)
            const response = require("../../mocks/articles__getCategories.json")
            setData(response)
        } else {
            await api.get('/articles/categories')
                .then(res => {
                    if (res.status === 200) {
                        setData(res.data)
                    }
                })
        }
        setLoading(false)
    }

    const getImageByTitle = (title: string) => {
        if (title === "Разум") {
            return mindImg
        }
        if (title === "Тело") {
            return bodyImg
        }
        return undefined
    }

    useEffect(() => {
        fetchData()
    }, [])

    const getItems = () => data.map((datum, i) => (
        <Grid
            key={i}
            container
            spacing={{
                xs: "24px",
                md: "130px",
            }}
            sx={{
                alignItems: {
                    xs: "end",
                    md: "start",
                }
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
            >
                <Typography
                    variant="h2"
                    sx={{
                        mb: "100px",
                    }}
                >
                    {t("ControlPainBlock.title")}
                </Typography>

                <LinkList
                    title={datum.title}
                    items={datum.categories.map(category => ({
                        title: category.title,
                        url: routes.platform + "?categoryIds=" + category.id,
                    }))}
                    icon={<ArrowSmallRight
                        color={theme.palette.text.tertiary}
                    />}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                order={{
                    xs: -1,
                    md: 1,
                }}
            >
                <PainImgWrapper>
                    <img
                        src={getImageByTitle(datum.title)}
                        alt={datum.title}
                    />
                </PainImgWrapper>
            </Grid>
        </Grid>
    ))

    useEffect(() => {
        if (ref.current) {
            // @ts-ignore
            if (typeof ref.current.goToSlide !== "undefined") {
                // @ts-ignore
                ref.current.goToSlide(Number(trigger), true)
            }
        }
    }, [trigger])

    return (
        <Box>
            <Container
                maxWidth="xl"
            >
                <Typography
                    variant="h2"
                    sx={{
                        mb: "32px",
                        display: {
                            xs: "block",
                            md: "none",
                        }
                    }}
                >
                    {t("ControlPainBlock.title")}
                </Typography>

                <Box
                    onScroll={() => console.log(1)}
                >
                    {loading ? (
                        <>
                            <BoxFlex
                                sx={{
                                    height: "150px",
                                    gridGap: "10px"
                                }}
                            >
                                <Box width="100%">
                                    <Skeleton width="100%"/>
                                    <Skeleton width="100%"/>
                                    <Skeleton width="100%"/>
                                    <Skeleton width="100%"/>
                                    <Skeleton width="100%"/>
                                </Box>
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        flexGrow: 1,
                                    }}
                                />
                            </BoxFlex>
                        </>
                    ) : (
                        <Carousel
                            ref={ref}
                            responsive={{
                                tablet: {
                                    breakpoint: {max: 999999, min: 0},
                                    items: 1
                                },
                            }}
                            arrows={false}
                            customTransition='transform 0.6s cubic-bezier(.46,.03,.52,.96) 0.5s'
                            // draggable={false}
                        >
                            {getItems()}
                        </Carousel>
                    )}
                </Box>
            </Container>
        </Box>
    );
};
